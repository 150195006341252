import { Injectable } from '@angular/core';
import {
    NbComponentStatus,
    NbGlobalLogicalPosition,
    NbGlobalPhysicalPosition,
    NbGlobalPosition,
    NbToastrService,
  } from '@nebular/theme';

@Injectable()
export class ToastrService {

    private position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    private preventDuplicates = false;
    private destroyByClick = true;
    private duration = 10000;
    private hasIcon = true;
    private statusSuccess: NbComponentStatus = 'success';
    private statusError: NbComponentStatus = 'warning';

    constructor(
        private toastrService: NbToastrService,
    ) { }

    /**
     * makeToast
     * @param number 0: Lỗi / 1: Thành Công.
     * @param title Tiêu đề.
     * @param content Nội dung.
     */
    makeToast(number: number, title: string, content: string) {
        if (number === 1) {
            this.showToast(this.statusSuccess, title, content);
        } else {
            this.showToast(this.statusError, title, content);
        }
    }
    private showToast(type: NbComponentStatus, title: string, body: string) {
        const config = {
          status: type,
          destroyByClick: this.destroyByClick,
          duration: this.duration,
          hasIcon: this.hasIcon,
          position: this.position,
          preventDuplicates: this.preventDuplicates,
        };
        const titleContent = title ? ` ${title}` : '';

        this.toastrService.show(
          body,
          `${titleContent}`,
          config);
    }
}
