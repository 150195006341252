import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigBills } from '../../models/config-bills.model';
import { Store } from '@ngrx/store';
import { ServerResponse } from '../../models/types';

@Injectable()
export class BillDetailsService {
    url: string;

    constructor(
        private http: HttpClient,
        private storeBill: Store<ConfigBills>,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getBillsFormat() {
      const nv = JSON.parse(localStorage.getItem('_user'));
      const MaCH: string = nv.MaCH;
      if (MaCH != '0000') {
        return this.http.post(`${this.url}api/ConfigBills/getdata_bills`, { MaCH: MaCH }).toPromise()
          .then((result: ServerResponse) => {
            console.log(result);
            if (result.code === 1) {
              this.storeBill.dispatch({ type: 'INIT_BILLS', configBills: result.data });
              return result.data;
            } else {
              throw new Error(result.message);
            }
          });
      }
      else {
      return this.http.get(`${this.url}api/ConfigBills/getdata_bills`).toPromise()
          .then((result: ServerResponse) => {
            console.log(result);
            if (result.code === 1) {
              this.storeBill.dispatch({ type: 'INIT_BILLS', configBills: result.data });
              return result.data;
            } else {
              throw new Error(result.message);
            }
          });
      }

    }

    setBillsFormat(data: ConfigBills) {
        return this.http.post(`${this.url}api/ConfigBills/setBillFormat`, {
            MaCH: data.MaCH,
            TenCH: data.TenCH,
            BillHeader: data.BillHeader,
            BillFooter: data.BillFooter,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                this.storeBill.dispatch({type: 'UPDATE_BILLS', data: data});
            } else {
                throw new Error('Thất bại!');
            }
        })
    }
}
