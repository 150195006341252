import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckboxList } from '../models/file.model';
import { ServerResponse } from '../models/types';

@Injectable()
export class ImportFileService {
url: string;
constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string 
) {
    this.url = baseUrl;
    // console.log(this.url)
}

    call_Api(){
        return this.http.get(this.url + 'api/ImportFile/ListFiles').toPromise()
        .then(result => {
            return result
        })
        .catch(err => console.log(err))
    }

    send_Api(data: Array<CheckboxList>) {
        return this.http.post(this.url + 'api/ImportFile/insertFile',data).toPromise()
        .then(result => {
            return result
        })
        .catch(err => console.log(err))
    }

    sub_Importv2(userID: string){
        return this.http.post(`${this.url}api/ImportFile/importFilev2`,{
            userID:userID
        }).toPromise()
        .then((result: ServerResponse) => {
            if(result.code === 1) {
                console.log(result.data);
                return result
            } else {
                return result
                throw new Error(result.message);
            }
        })
    }
}
