import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../../models/types';
import { Logo } from '../../models/logo.model';

@Injectable()
export class LogoService {
    url: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseURL: string,
    ) {
        this.url = baseURL;
    }

    getFileName() {
    return this.http.get(this.url + 'api/Logo/getLogo').toPromise()
        .then((result: ServerResponse) => {
        if (result[0].code === 1) {
            return result[0].data;
        } else {
            throw new Error('Lỗi!');
        }
        })
        .catch(error => error);
    }

    uploadFile(formData: FormData) {
        return this.http.post(this.url + `api/Logo/UploadFile`, formData , {
            reportProgress: true,
            })
        .toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return true;
            } else {
                throw new Error(result.message);
            }
        });
    }

    uploadFileSQL(data: Logo) {
        return this.http.post(this.url + `api/Logo/setFileImage`, data , {
            reportProgress: true,
            })
        .toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

}
