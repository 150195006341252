import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { UriPayment, KeyAPI } from '../models/online-payment';

@Injectable()
export class PaymentService {
  url: string;

    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getlistPaymentOnline() {
      return this.http.get(`${this.url}api/Payment/getDsHTTT`).toPromise()
      .then((result: ServerResponse) => {
          if (result[0].code === 1) {
              // this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
              return result[0].data;
          } else {
              throw new Error('Lỗi! Không thể lấy danh sách hình thức thanh toán.');
          }
      });
  }

  getURIPayment(maHTTT: string)
  {
    return this.http.post(`${this.url}api/Payment/getURI`,{MaHTTT: maHTTT}).toPromise()
    .then((result: ServerResponse) => {
        if (result[0].code === 1) {
            // this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
            return result[0].data;
        } else {
            throw new Error('Lỗi! Không thể lấy danh sách hình thức thanh toán.');
        }
    });
  }
  getKeyAPIPayment(maHTTT: string)
  {
    return this.http.post(`${this.url}api/Payment/getKeyAPI`,{MaHTTT: maHTTT}).toPromise()
    .then((result: ServerResponse) => {
        if (result[0].code === 1) {
            // this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
            return result[0].data;
        } else {
            throw new Error('Lỗi! Không thể lấy danh sách hình thức thanh toán.');
        }
    });
  }

  async create_URI(value: UriPayment) {
    return await this.http.post(`${this.url}api/Payment/create_URI`, {
        PaymentID: value.PaymentID,
        URI: value.URI,
        TypeOfURI: value.TypeOfURI,
        Creator: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}

async update_URI(value: UriPayment) {
    return await this.http.post(`${this.url}api/Payment/update_URI`, {
        PaymentID: value.PaymentID,
        URI: value.URI,
        TypeOfURI: value.TypeOfURI,
        UpdatedPerson: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}

async delete_URI(value: UriPayment) {
    return await this.http.post(`${this.url}api/Payment/delete_URI`, {
        PaymentID: value.PaymentID,
        URI: value.URI,
        TypeOfURI: value.TypeOfURI,
        UpdatedPerson: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}

async create_APIKey(value: KeyAPI) {
    return await this.http.post(`${this.url}api/Payment/create_APIKey`, {
        PaymentID: value.PaymentID,
        MerchantID: value.MerchantID,
        PublicKey: value.PublicKey,
        StoreID: value.StoreID,
        Creator: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}

async update_APIKey(value: KeyAPI) {
    return await this.http.post(`${this.url}api/Payment/update_APIKey`, {
        PaymentID: value.PaymentID,
        MerchantID: value.MerchantID,
        PublicKey: value.PublicKey,
        StoreID: value.StoreID,
        UpdatedPerson: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}

async delete_APIKey(value: KeyAPI) {
    return await this.http.post(`${this.url}api/Payment/delete_APIKey`, {
        PaymentID: value.PaymentID,
        StoreID: value.StoreID,
        UpdatedPerson: value.Creator,
    }).toPromise()
    .then((result: ServerResponse) => {
        if (result.code === 1) {
            return result.message;
        } else {
            throw new Error(result.message);
        }
    });
}
  }
