import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    localStorage.removeItem('_user');
    var uid = this.getCookie('_acceptKey');
    //const urlTPA_UID = urlTPA + '/auth/check-uid/' + uid;
    //window.location.href = urlTPA_UID;
    this.router.navigate(['/auth/check-uid/' + uid]);
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == '  ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
