import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">Created by <b><a href="http://powersoft.vn" target="_blank">POWERSOFTVN</a></b></span>
    <div class="socials">
    <a target = "_blank"><img src="../../../../assets/images/Logo/Logo3_u372.png" /></a>
</div>
  `,
})
export class FooterComponent {
}
