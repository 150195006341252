import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { Store } from '@ngrx/store';
import { Stalls, Stalls_AllInfo } from '../models/stalls.model';

@Injectable()
export class StallsService {
    urlTPC: string;
    url: string;
    constructor(
        private http: HttpClient,
        private store: Store<Stalls[]>,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getStalls(maCH: string) {
        return this.http.post(`${this.url}api/Stalls/getDsQuayBan`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
                return result[0].data;
            } else {
                throw new Error('Lỗi! Không thể lấy danh sách quầy bán.');
            }
        });
    }

  getStores() {
    const nv = JSON.parse(localStorage.getItem('_user'));
    const MaCH: string = nv.MaCH;
    const MaCH_TT = localStorage.getItem('_maCHTT');
    if (MaCH != '0000' && MaCH != MaCH_TT) {
      return this.http.post(`${this.url}api/Stalls/getDsCuaHang`, { MaCH: MaCH }).toPromise()
        .then((result: ServerResponse) => {
          if (result[0].code === 1) {
            return result[0].data;
          } else {
            throw new Error('Lỗi! Không thể lấy danh sách cửa hàng.');
          }
        });
    }
    else {
       return this.http.get(`${this.url}api/Stalls/getDsCuaHang`).toPromise()
        .then((result: ServerResponse) => {
          if (result[0].code === 1) {
            return result[0].data;
          } else {
            throw new Error('Lỗi! Không thể lấy danh sách cửa hàng.');
          }
        });
    }
    }

    async KickLog(key: string) {
        // const url = this.getURL().then((data: string) => data);
        await this.getURL();
        console.log('KickLog');
        console.log('URL: ' + this.urlTPC );
        return this.http.post(`${this.url}api/Stalls/getLogKich`, {Key: key, Url: this.urlTPC}).toPromise()
        .then((result: ServerResponse) => {
            this.store.dispatch({type: 'UPDATE_STALL', Key: key});
        });
    }

    getHistorySignIn(maCH: string, date) {
        return this.http.post(`${this.url}api/Stalls/getHistorySignIn`, {MaCH: maCH, Date: date}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code = 1) {
                return result[0].data;
            }
        });
    }

  async getHQ(): Promise<string> {
    // this.http.get('manifest.json', { responseType: 'json' }).subscribe(async name => {
    //     this.urlTPC = await name['config_topos'];
    // });
    return await this.http.get('manifest.json', { responseType: 'json' }).toPromise()
          .then(async (name) => {
              return name['HQ'];
          });
  }

     async getURL() {
        // this.http.get('manifest.json', { responseType: 'json' }).subscribe(async name => {
        //     this.urlTPC = await name['config_topos'];
        // });
        this.urlTPC = await this.http.get('manifest.json', { responseType: 'json' }).toPromise()
        .then(async name => {
            return name['config_topos'];
        });
    }

    getAccess_Stalls_AllInfo(maCH: string) {
        return this.http.post(`${this.url}api/Stalls/getAccess_StallAllInfo`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            }
        });
    }

    async setAccess_StallAllInfo(value: Stalls_AllInfo) {
        return await this.http.post(`${this.url}api/Stalls/createAccess_StallAllInfo`, {
            maQuay: value.maQuay,
            key: value.key,
            trangthaiQuay: value.trangthaiQuay,
            tenQuay: value.tenQuay,
            maCH: value.maCH,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result.message;
            } else {
                throw new Error(result.message);
            }
        });
    }

    putAccess_StallAllInfo(value: Stalls_AllInfo) {
        return this.http.post(`${this.url}api/Stalls/updateAccess_StallAllInfo`, {
            maQuay: value.maQuay,
            key: value.key,
            trangthaiQuay: value.trangthaiQuay,
            tenQuay: value.tenQuay,
            maCH: value.maCH,
        }).toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return result;
            } else {
                throw new Error(result.message);
            }
        });
    }

}
