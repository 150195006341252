import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { Store } from '@ngrx/store';
import { Stalls, Stalls_AllInfo } from '../models/stalls.model';

@Injectable()
export class ReportService {
    urlTPC: string;
    url: string;
    constructor(
        private http: HttpClient,
        private store: Store<Stalls[]>,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getStalls(maCH: string) {
        return this.http.post(`${this.url}api/Stalls/getDsQuayBan`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                this.store.dispatch({type: 'INIT_STALLS', stalls: result[0].data});
                return result[0].data;
            } else {
                throw new Error('Lỗi! Không thể lấy danh sách quầy bán.');
            }
        });
    }

    getStores(maNV:string) {
      return this.http.post(`${this.url}api/Stalls/getDsCuaHang_Report`, { MaNV: maNV }).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            } else {
                throw new Error('Lỗi! Không thể lấy danh sách cửa hàng.');
            }
        });
    }

 
    getSaleReport(maCH: string, date) {
      return this.http.post(`${this.url}api/Sales/getSalesReport`, {MaCH: maCH, Date: date}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code = 1) {
                return result[0].data;
            }
        });
    }

     async getURL() {
        // this.http.get('manifest.json', { responseType: 'json' }).subscribe(async name => {
        //     this.urlTPC = await name['config_topos'];
        // });
        this.urlTPC = await this.http.get('manifest.json', { responseType: 'json' }).toPromise()
        .then(async name => {
            return name['config_topos'];
        });
    }

    getAccess_Stalls_AllInfo(maCH: string) {
        return this.http.post(`${this.url}api/Stalls/getAccess_StallAllInfo`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            }
        });
    }
}
