import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { Location } from '@angular/common';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auth',
  template: `
  <nb-layout>
    <nb-layout-column>
      <nb-card>
        <nb-card-header>
          <nav class="navigation">
            <a href='' class='link' aria-label="Back" (click)='Back()'>
            <nb-icon class='ion-arrow-left-b'>
            </nb-icon>
              Quay về TOPOSonCLOUD
            </a>
          </nav>
        </nb-card-header>
        <nb-card-body>
          <nb-auth-block>
            <router-outlet></router-outlet>
          </nb-auth-block>
        </nb-card-body>
      </nb-card>
    </nb-layout-column>
  </nb-layout>
  `,
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnDestroy {
  private alive = true;
  subscription: any;
  authenticated = false;
  token: string;
  constructor(private http: HttpClient, protected auth: NbAuthService, protected location: Location) {
    this.subscription = auth.onAuthenticationChange()
    .pipe(takeWhile(() => this.alive))
    .subscribe((authenticated: boolean) => {
      this.authenticated = authenticated;
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  Back() {
    this.http.get('manifest.json', { responseType: 'json' }).subscribe(async name => {
      const urlTPC: string = await name['config_topos'];
      this.deleteCookie('_acceptKey');
      window.location.href = urlTPC;
    });
  }

  deleteCookie(cname) {
    const d = new Date();
    d.setTime(d.getTime() + (0 * 24 * 60 * 60 * 1000));
    const expires = 'expires = ' + d.toUTCString();
    document.cookie = cname + '=;' + expires + ';path=/';
  }

}
